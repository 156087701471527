import { FolderOutlined, LogoutOutlined } from '@ant-design/icons'
import { Affix, Divider, Menu } from 'antd'
import { useAuth } from 'hooks/useAuth'
import { useLocation } from 'wouter'

const menus = [
  {
    name: 'Home',
    path: '/',
    items: [],
  },
  {
    name: 'Fira',
    subpath: '/fira',
    items: [
      {
        title: 'Propostes Actives',
        path: '/fira-proposals-active',
      },
      {
        title: 'Propostes per presentar',
        path: '/fira-proposals-draft',
      },
      {
        title: 'Entitats',
        path: '/fira-entities',
      },
      {
        title: 'Acreditats',
        path: '/fira-accreditations',
      },
      {
        title: 'Pagaments',
        path: '/fira-payments',
      },
      {
        title: 'Espectacles 2024',
        path: `/?path=/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}2024/espectacles/`,
      },
      {
        title: 'Actuacions 2024',
        path: `/?path=/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}2024/actuacions/`,
      },
      {
        title: 'Activitats professionals 2024',
        path: `/?path=/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}2024/activitats-professionals/`,
      },
    ],
    Icon: FolderOutlined,
  },
]

const findCurrent = (items) => {
  const url = new URL(window.location.href)
  const path = url.pathname + url.search
  const found = ['Home']
  for (let i = 0; i < items.length; i++) {
    if (path.startsWith(items[i].subpath)) {
      found[0] = items[i].name
      for (let k = 0; k < items[i].items.length; k++) {
        const curr = items[i].items[k]
        if (path.startsWith(curr.path)) {
          found[1] = k
          break
        }
      }
      break
    }
  }
  return found
}

function Logo() {
  const auth = useAuth()
  return (
    <>
      <div className="navbar-item">
        <img
          src={process.env.PUBLIC_URL + '/logo.svg'}
          alt="Guillotina logo"
          height="60"
          style={{ paddingLeft: '20px', marginTop: '10px' }}
        />
      </div>
      <Divider />
      <div className="navbar-item">{auth.username}</div>
      <Divider />
    </>
  )
}

export function MainMenu({ onLogout }) {
  const [, setLocation] = useLocation()

  const clickMenu = (ev) => {
    if (ev.item.props.href) {
      setLocation(ev.item.props.href)
    }
  }
  const [sub, key] = findCurrent(menus)

  return (
    <Affix>
      <Menu
        onClick={clickMenu}
        style={{ width: '100%' }}
        defaultOpenKeys={[sub]}
        selectedKeys={[key !== undefined ? `${sub}_${key}` : `${sub}`]}
        mode="inline"
        className="menu-sidebar"
      >
        <Logo />

        {menus.map((block) => {
          if (block.items.length > 0) {
            return (
              <Menu.SubMenu
                key={block.name}
                title={
                  <span>
                    <block.Icon /> <span>{block.name}</span>
                  </span>
                }
              >
                {block.items.map((ele, idx) => (
                  <Menu.Item key={block.name + '_' + idx} href={ele.path}>
                    {ele.title}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          }
          return (
            <Menu.Item href={block.path} key={block.name}>
              {block.name}
            </Menu.Item>
          )
        })}
        <Menu.Item onClick={onLogout} key="logout">
          <span>
            <LogoutOutlined />
          </span>
          Sortir
        </Menu.Item>
      </Menu>
    </Affix>
  )
}
